@font-face {
    font-family: 'Satisfy';
    src: local('Satisfy-Regular'), url(../assets/fonts/Satisfy-Regular.ttf) format('truetype');
    font-display: swap;
}

 h1, h2, h3, h4, a, button, label, .pinkSquare, .ReactModal__Content, .ReactModal__Overlay, .react-player__preview, .gymphotofade{
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

.bm-menu-wrap  {
    width: 40vw !important;
}
.logotext  {
    animation: 1s ;
    text-shadow:
    0 0 0.25vh #fff,
    0 0 0.5vh #fff,
    0 0 2.5vh #c9529b;
}
.logoContainer:hover .logotext {
    animation: logoPulse 1.25s infinite alternate;
}
@keyframes logoPulse {
    
      0% { 
          text-shadow:
          0 0 0.25vh #fff,
          0 0 0.5vh #fff,
          0 0 2.5vh #c9529b,
      } 
      100% {
        text-shadow:
        0 0 0.25vh #fff,
        0 0 0.5vh #fff,
        0 0 2.75vh #c9529b,
        0 0 4vh #c9529b,
    }
}

//main header links
.nav-menu-option, .nav-menu-optionLogin{
    position: relative;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    -webkit-transition: all .2s ease-in;
    transition: all .2s ease-in;
    color: #fff;
  }
  .nav-menu-option:after, .nav-menu-optionLogin:after {    
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 5px;
    left: 50%;
    position: absolute;
    background: #c9529b;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .nav-menu-option:hover, .nav-menu-optionLogin:hover { 
      color: #ff65c3;
  }
  .nav-menu-option:hover:after, .nav-menu-optionLogin:hover:after{ 
    width: 100%; 
    left: 0; 
}

.loginHoverMessage{
    -moz-transition: all .25s ease-in;
    -o-transition: all .25s ease-in;
    -webkit-transition: all .25s ease-in;
    transition: all .25s ease-in;
    opacity: 0;
}
 .nav-menu-optionLogin:hover   ~ .loginHoverMessage { 
    opacity: 1;
}