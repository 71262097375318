.ReactModal__Overlay {
	background-color: rgba(0, 0, 0, 0.5) !important;
}

.f2f {
	transition: transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.f2f:hover {
	transform: scale(1.15);
}

.subscribe-container {
	width: 100vw;
	min-height: 93vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #000;
	padding: 20px;
}

.subscribe-title {
	color: #fff;
	font-family: 'bebas', sans-serif;
	font-weight: 200;
	margin-bottom: 0;
	margin-top: 13vh;
}

.subscribe-description {
	color: #ccc;
	width: 80vw;
	max-width: 600px;
	text-align: center;
}

.subscribe-form {
	width: 50vw;
	min-width: 300px;
	max-width: 700px;
	background: #333;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.subscribe-label {
	color: #fff;
	font-weight: bold;
}

.subscribe-input {width: calc(100% - 20px);
	padding: 10px;
	margin-top: 5px;
	margin-bottom: 15px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background-color: #555;
	color: #fff;
}

.subscribe-submit-container {
	display: flex;
	align-items: center;
}

.subscribe-button {
	font-weight: 200;
	font-family: 'bebas', sans-serif;
	padding: 10px 20px;
	background-color:rgb(255, 101, 195);
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
}

.subscribe-button:disabled {
	background-color: #ccc;
	cursor: not-allowed;
}

.subscribe-feedback {
	color: #fff;
	margin-top: 0;
	margin-bottom: 0;
	margin-left: 20px;
}