
.rce-mbox-left-notch > *{
    fill: #d64cA8;
}
.rce-mbox-right-notch > *{
    fill: #d64cA8;
}
.rce-mbox{
    background: #d64cA8 !important;
}
.rce-mbox-title{
   font-weight: 700 !important;
   font-size: 2vw !important;
   pointer-events: none;
}
.rce-mbox-text{
    font-size: 0.7vw !important;
}
.rce-mbox-time{
    color:rgba(0,0,0,0) !important;
}
.rce-mbox-title{
    font-size: 2vw !important;
}
.rce-mbox-text{
    font-size: 1.5vw !important;
}