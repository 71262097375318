
@font-face {
    font-family: 'Bebas';
    src: local('Bebas'), url(../assets/fonts/Bebas.ttf) format('truetype');
    font-display: swap;
}
.chatheadglow, .chatTextGlow {
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
  .chatheadbutton:hover > .chatheadglow{
    opacity: 1;
  }
  .chatheadbutton:hover + .chatTextGlow{
    opacity: 1;
  }

@mixin btn-border-drawing ($color: #ccc, $hover: black, $width: 0.15vw, $vertical: top, $horizontal: left, $duration: 0.25s) {
    box-shadow: inset 0 0 0 $width $color;
    text-Shadow: 0 0 1vw rgba(0,0,0,0.5);
    background:none;
    cursor: pointer;
    border: none;
    color: $color;
    transition: color $duration $duration/3;
    position: relative;
    padding: 0em 0.75vw;
    line-height: 2;

    &::before,
    &::after {
      border: 0 solid transparent;
      box-sizing: border-box;
      content: '';
      pointer-events: none;
      position: absolute;
      width: 0; height: 0;
      
      #{$vertical}: 0; 
      #{$horizontal}: 0;
    }
  
    &::before {
      $h-side: if($horizontal == 'left', 'right', 'left');
      
      border-#{$vertical}-width: $width;
      border-#{$h-side}-width: $width;
    }
    
    &::after {
      $v-side: if($vertical == 'top', 'bottom', 'top');
      
      border-#{$v-side}-width: $width;
      border-#{$horizontal}-width: $width;
    }
    
    &:hover {
      color: $hover;
      
      &::before,
      &::after {
        border-color: $hover;
        transition: border-color 0s, width $duration, height $duration;
        width: 100%;
        height: 100%;
      }
      
      &::before { transition-delay: 0s, 0s, $duration; }
      
      &::after { transition-delay: 0s, $duration, 0s; }
    }
  }
  
  .draw-border {
    @include btn-border-drawing(#ffffff, #ff65c3, 0.25vw, bottom, right);
  }  
  .draw-border-2 {
    @include btn-border-drawing(#1a211d, #ffffff, 0.25vw, bottom, right);
  }
  .draw-border-login {
    @include btn-border-drawing(#ffffff, #ff65c3, 0.25vw, bottom, right);
  }

  .draw-borderCart {
    font-family:bebas;
    font-size: 0.75vw;
    @include btn-border-drawing(#ffffff, #ff65c3, 0.25vw, bottom, right,);
  }

  
  .homeScrollArrow, .homeScrollArrowUp, .homeScrollArrowDown, .loginToRegisterLink{
    transition: transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.homeScrollArrowUp:hover {
    transform: scale(1.5) ;
}
.homeScrollArrowDown:hover {
    transform: scale(1.5);
}
.loginToRegisterLink:hover {
    transform: scale(1.1);
}
