.newLabel{
	color:#ff65c3;
	font-size: 2.5vh;
	position: absolute;
	animation: zoominoutsinglefeatured 1.5s  infinite;
}

@keyframes zoominoutsinglefeatured {
    0% {
        transform: scale(1,1)  translate(-50%, 55%);
    }
    50% {
        transform: scale(1.2,1.2)  translate(-50%, 55%);
    }
    100% {
        transform: scale(1,1) translate(-50%, 55%);
    }
}